const TEAM_NPE_UK = [
  "marc.pinder@hsf.com",
  "david.harper@hsf.com",
  "bruno.silva@hsf.com",
];

const TEAM_NPE_INDIA = [
  "dipesh.wadhwa@hsf.com",
  "yogesh.palve@hsf.com",
  "hitesh.jethva@hsf.com",
  "victor.lobo@hsf.com",
  "appa.yele@hsf.com",
];

const TEAM_NPE = [...TEAM_NPE_UK, ...TEAM_NPE_INDIA];

const TEAM_INVITATION = [
  "thulasi.ramakrishnaswamy@hsf.com",
  "susanna.kipping@hsf.com",
  "tayah.ellis@hsf.com",
  "caroline.bedford@hsf.com",
  "gabriella.maughan@hsf.com",
  "emilly.edsell@hsf.com",
  "bilal.syed@hsf.com",
  "carolyn.wensley@hsf.com",
  "simon.cheung@hsf.com",
  "gareth.jackson@hsf.com",
  "carol.bridge@hsf.com",
  "anjali.chadha@hsf.com",
  "andrew.prestwood@hsf.com",
  ...TEAM_NPE,
];

const TEAM_KNOWLEDGE = [
  "stuart.print@hsf.com",
  "steve.andrew@hsf.com",
  "haig.tyler@hsf.com",
];

export default {
  logs: import.meta.env.VITE_FEATURE_FLAG_LOGGING,
  features: [
    {
      // Enable Cloud Data setting for the user
      name: "enableCloudDataUseSetting",
      visible: false,
      previewMode: true,
      allowedUsers: [],
    },
    // Allow users to create/display test matter services
    {
      name: "canCreateTestMatterServices",
      visible: false,
      previewMode: true,
      allowedUsers: [],
    },
    // Show Sector Hub Insight
    {
      name: "showSectorInsight",
      visible: false,
      previewMode: false,
      allowedUsers: [],
    },
    // Show Sector Hub Insight on Home Dashboard
    {
      name: "showDashboardSectorInsight",
      visible: false,
      previewMode: false,
      allowedUsers: [],
    },
    // Redesigned prototype homepage for knowledge team
    {
      name: "showNewHomepageWidgets",
      visible: false,
      previewMode: false,
      allowedUsers: [...TEAM_NPE, ...TEAM_KNOWLEDGE],
    },
    // Show Last Matter Activity on Client Insight page; disabled until API respects IB Matters
    {
      name: "showClientInsightLastActivity",
      visible: false,
      previewMode: false,
      allowedUsers: [...TEAM_NPE],
    },
    // Show Client Credentials on client insight
    {
      name: "showClientCredentials",
      visible: false,
      previewMode: false,
      allowedUsers: [],
    },
    // Allow the user to see the new SSRS reports link on Matters
    {
      name: "showSSRSReports",
      visible: false,
      previewMode: false,
      allowedUsers: [],
    },
    // Show Preview Mode SSRS Reports
    {
      name: "showPreviewSSRSReports",
      visible: false,
      previewMode: true,
      allowedUsers: [],
    },
    // Allow the user to see the Forecast section in People, and the Forecast widget on Dashboard
    // @deprecated
    {
      name: "showWorkAllocation",
      visible: true,
      previewMode: false,
      allowedUsers: [],
    },
    // Show the Career Interests section in People menu
    // @deprecated
    {
      name: "showWorkAllocationCareerDevelopment",
      visible: true,
      previewMode: false,
      allowedUsers: [],
    },
    // Allow the user to see the Arbitration section in Matter Genesis
    {
      name: "showArbitration",
      visible: true,
      previewMode: false,
      allowedUsers: [],
    },
    // Enable WebPush Notifications
    {
      name: "enableNotifications",
      visible: false,
      previewMode: false,
      allowedUsers: [...TEAM_NPE_UK],
    },
    // Enable Show Invitation
    {
      name: "showInvitation",
      visible: false,
      previewMode: false,
      allowedUsers: [...TEAM_INVITATION],
    },
    // Show the matter credentials
    {
      name: "showMatterCredentials",
      visible: false,
      previewMode: false,
      allowedUsers: [],
    },
    // Enable HSF.AI token information
    {
      name: "showHSFAITokens",
      visible: false,
      previewMode: false,
      allowedUsers: [...TEAM_NPE],
    },
    // Show DXP content in sectors/dxp"
    {
      name: "showSectorDXPContent",
      visible: false,
      previewMode: false,
      allowedUsers: [...TEAM_NPE],
    },
    // Show New Litigation in Genesis
    {
      name: "showNewGenesis",
      visible: true,
      previewMode: false,
      allowedUsers: [],
    },
    // Show matter Insider Report"
    {
      name: "matterInsiderReport",
      visible: true,
      previewMode: false,
      allowedUsers: [],
    },
    // Show Forecast Scheduling
    {
      name: "ForecastScheduling",
      visbile: false,
      previewMode: true,
      allowedUsers: [],
    },
  ],
};
