<script setup lang="ts">
import { inject, computed } from "vue";
import { useSettings } from "@/composables/UserSettings.js";
import { USER_ROLE, SETTING_HSFAI_ENABLED } from "@/config/constants.js";
import authService from "@/services/authService";

// Replace this type once auth service upgraded to typescript and use export from there
type AuthClass = typeof authService;
// Replace this code once feature-flag library upgraded to v3
interface FeatureFlag {
  isVisible(key: string): boolean,
};

const $auth = inject<AuthClass>("auth");
const $featureFlag = inject<FeatureFlag>("$featureFlag");

const props = defineProps({
  iconActivator: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: "",
  },
  menuUp: {
    type: Boolean,
    default: false,
  },
  menuDown: {
    type: Boolean,
    default: true,
  },
});

const showAIOption = useSettings(SETTING_HSFAI_ENABLED, false);

const showHSSTool = computed(() => {
  return $auth?.hasRole(USER_ROLE["hssAccess"]) ?? false;
});

const showCompaniesHouse = computed(() => {
  return $auth?.hasRole(USER_ROLE["CompaniesHouseAccess"]) ?? false;
})
</script>

<template>
  <v-menu :location="props.menuUp ? 'top' : 'bottom'">
    <template #activator="{ props: menuProps }">
      <!-- If Mobile mode, using just an icon -->
      <v-btn
        v-if="props.iconActivator"
        id="toolsMenu"
        :color="props.color"
        append-icon="mdi-tools"
        :text="$t('tools.title')"
        v-bind="menuProps"
      />

      <!-- If Desktop mode, using just text -->
      <v-btn
        v-else
        id="toolsMenu"
        variant="flat"
        :color="color"
        :text="$t('tools.title')"
        v-bind="menuProps"
      />
    </template>
    <v-list>
      <v-list-item
        class="my-1"
        rounded="lg"
        :title="$t('tools.terminology')"
        :to="{ name: 'terminology' }"
      />
      <v-list-item
        class="my-1"
        rounded="lg"
        :title="$t('tools.genesis')"
        :to="{ name: 'matter-groups' }"
      />
      <v-list-item
        v-if="showHSSTool"
        class="my-1"
        rounded="lg"
        :to="{ name: 'hss' }"
        :title="$t('tools.hss').toUpperCase()"
      />
      <v-list-item
        v-if="$featureFlag?.isVisible('showInvitation')"
        class="my-1"
        rounded="lg"
        :to="{ name: 'invitation' }"
        :title="$t('invitations.invitations')"
      />
      <v-list-item
        v-if="showAIOption"
        class="my-1"
        rounded="lg"
        :to="{ name: 'aiChat' }"
        :title="$t('ai.title')"
      />
      <v-list-item
        v-if="showCompaniesHouse"
        class="my-1"
        rounded="lg"
        :to="{ name: 'companies-house' }"
        :title="$t('tools.companies-house.title')"
      />
    </v-list>
  </v-menu>
</template>
