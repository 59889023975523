export default [
  {
    path: "/ai/",
    component: () => import("@/modules/hsf.ai/_views/ChatShell.vue"),
    redirect: { name: "aiChat" },
    children: [
      {
        path: "chat/:id?",
        name: "aiChat",
        component: () => import("@/modules/hsf.ai/_views/ChatAi.vue"),
        meta: {
          title: "Generative AI",
          noShell: true,
        },
      },
      {
        path: "chat/import-history",
        name: "aiImportHistory",
        component: () => import("@/modules/hsf.ai/_views/ImportHistory.vue"),
        meta: {
          title: "Import AI History",
          noShell: true,
        },
      }
    ],
  },
];
