<template>
  <v-dialog
    v-model="menuOpen"
    :fullscreen="$vuetify.display.mobile"
    :max-width="$vuetify.display.mobile ? '100%' : 400"
  >
    <template #activator="{ props: onMenu }">
      <v-tooltip location="bottom">
        <template #activator="{ props: onTooltip }">
          <v-btn icon v-bind="{ ...onMenu, ...onTooltip }">
            <user-photo
              :name="$auth.getName()"
              :email="$auth.getEmailAddress()"
              :size="avatarSize"
              :highlight-border="usePreviewMode"
            />
          </v-btn>
        </template>
        {{ getTooltipText }}
      </v-tooltip>
    </template>

    <v-card rounded="lg">
      <v-card-title class="d-flex justify-space-between align-center">
        <img
          alt="Logo"
          :src="getLogo"
          height="16px"
          width="16px"
        >
        <app-name class="text-body-2 font-weight-light" />
        <v-btn
          icon="mdi-close"
          variant="flat"
          size="small"
          @click="menuOpen = false"
        />
      </v-card-title>

      <v-list>
        <v-list-item :title="$auth.getName()" :subtitle="$auth.getEmailAddress()">
          <template #prepend>
            <user-photo
              :name="$auth.getName()"
              :email="$auth.getEmailAddress()"
              no-presence
              :size="48"
              class="mr-2"
            />
          </template>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list class="py-0 rounded-lg">
        <!-- Your Profile (but not if already on it)-->
        <v-list-item
          v-if="!isOnUserProfile"
          :to="{
            name: 'people-profile',
            params: { id: $auth.getEmailAddress() },
          }"
        >
          <template #prepend>
            <v-icon icon="mdi-account" />
          </template>
          <v-list-item-title>
            {{ $t("common.components.user-settings.your-profile") }}
          </v-list-item-title>
        </v-list-item>

        <!-- Settings Page -->
        <v-list-item v-if="!isOnSettingsPage" :to="{ name: 'settings' }">
          <template #prepend>
            <v-icon icon="mdi-cog" />
          </template>
          <v-list-item-title>
            {{ $t("common.components.user-settings.settings") }}
          </v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item :to="{ name: 'logout' }" prepend-icon="mdi-logout-variant">
          {{ $t("common.core.logout") }}
        </v-list-item>
      </v-list>

      <v-divider />

      <!-- Version Number -->
      <div class="bg-background text-center text-caption">
        <i18n-t keypath="common.components.user-settings.version">
          <template #appVersion>
            {{ getAppVersion }}
          </template>
        </i18n-t>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { SETTING_PREVIEW_MODE } from "@/config/constants.js";

import appLogo from "@/assets/matter-insight-logo.svg";
import appLogoDark from "@/assets/matter-insight-logo-white.svg";
import { useSettings } from "@/composables/UserSettings.js";

export default {
  props: {
    avatarSize: {
      type: Number,
      default: 48,
    },
  },
  setup() {
    const usePreviewMode = useSettings(SETTING_PREVIEW_MODE, false);
    usePreviewMode.value = !!usePreviewMode.value; // convert to proper boolean from potential string

    return { usePreviewMode };
  },
  data() {
    return {
      menuOpen: false,
      appLogo: null,
      appLogoDark: null,
      appVersion: import.meta.env.VITE_VERSION,
    };
  },
  computed: {
    getAppVersion() {
      let appVersion = this.appVersion;
      // If version is in staging then truncate string
      if (
        this.appVersion !== "Development" &&
        this.appVersion.charAt(0) !== "v"
      ) {
        // Split commit at - index
        const split = this.appVersion.split("-");
        // Get first 7 characters of commit id and join with second half of id
        appVersion = split[0].slice(0, 7) + `-${split[1]}`;
      }

      return appVersion;
    },
    getTooltipText() {
      return this.usePreviewMode ? "Preview Mode" : "User Settings";
    },
    isOnSettingsPage() {
      return this.$route.name === "settings";
    },
    isOnUserProfile() {
      return (
        this.$route.name === "people-profile" &&
        this.$route.params.id === this.$auth.getEmailAddress()
      );
    },
    getLogo() {
      return this.$vuetify.theme.current.dark ? this.appLogoDark : this.appLogo;
    },
  },
  watch: {
    "$route.path"() {
      this.menuOpen = false;
    },
  },
  created() {
    this.appLogo = appLogo;
    this.appLogoDark = appLogoDark;
  },
};
</script>
