export default [
  {
    path: "/companies-house",
    name: "companies-house",
    redirect: { name: "companies-house-dashboard" },
  },
  {
    path: "/companies-house/dashboard/",
    name: "companies-house-dashboard",
    component: () =>
      import("@/modules/companies-house/_views/CompaniesHouseDashboard.vue"),
    meta: {
      title: "Companies House",
    },
  },
]