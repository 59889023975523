const createStore = (app) => {
  const getters = {
    /**
     * Get the previous route to navigate back
     * @param {*} state
     * @returns The previous route object
     */
    getPreviousRoute(state) {
      const historyLen = state.routerHistory.length;
      if (historyLen < 2) {
        return null;
      }
      return state.routerHistory[historyLen - 1].path;
    },
    /**
     * Get the route history stack
     * @param {*} state
     * @returns The array of route history
     */
    getRouterHistory(state) {
      return [...state.routerHistory];
    },
  };

  const actions = {
    setRouterLoggingIn({ commit }, isLoggingIn) {
      commit("setLoggingIn", isLoggingIn);
    },
    /**
     * Show the UI for activating the service worker / reloading the page
     * @param {*} param0
     * @param {boolean} requiresUpgrade true to show UI
     */
    setServiceWorkerUpgrade({ commit }, requiresUpgrade) {
      commit("setServiceWorkerUpgrade", requiresUpgrade);
    },
    /**
     * Add a route to the route history array
     * @param {*} param0
     * @param {Object} route the route object
     */
    addRoutePathToHistory({ commit }, route) {
      commit("addRouteToHistory", route);
    },
  };

  const mutations = {
    addRouteToHistory(state, route) {
      // Add the new route at the beginning of the array
      state.routerHistory.unshift(route);
      // If more than 5 then remove the oldest (last) entry
      if (state.routerHistory.length > 5) {
        state.routerHistory.pop();
      }
    },
    setLoggingIn(state, isLoggingIn) {
      state.isRouterLoggingIn = isLoggingIn;
    },
    setFeatureFlagsUpdated(state, featureFlagsUpdated) {
      state.featureFlagsUpdated = featureFlagsUpdated;
    },
    setServiceWorkerUpgrade(state, requiresUpgrade) {
      state.requiresServiceWorkerUpgrade = requiresUpgrade;
    },
  };

  const state = {
    isRouterLoggingIn: false,
    featureFlagsUpdated: false,
    showSettingsMenu: false,

    // Service Worker Status
    requiresServiceWorkerUpgrade: false,
    // Store router history
    routerHistory: [],
  };

  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
};

export default (app) => createStore(app);
