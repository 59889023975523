import "@fontsource/rubik/300.css";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/800.css";

import { createApp } from "vue";
import App from "./App.vue";
import createStore from "./store/index.js";
import createRouter from "./router/index.js";
import vuetify from "./plugins/vuetify.js";
import VueDOMPurifyHTML from "vue-dompurify-html";

import "@/styles/hurricane.theme.css";

import { pinia } from "@/plugins/pinia.js";
import AuthPlugin from "@/plugins/auth.js";
import CachePlugin from "@/plugins/cache.js";
import HttpPlugin from "@/plugins/http.js";
import PowerBIPlugin from "@/plugins/powerBi.js";
import FeatureFlagsPlugin from "@/plugins/featureFlags.js";
import ApplicationInsightsPlugin from "@/plugins/applicationInsights.js";
import { Sanitize, SanitizeDefaults } from "@/plugins/sanitize.js";
import currencySymbol from "@/plugins/currencySymbol.js";
import googleCharts from "@/plugins/googleCharts.js";
import PortalVue from "@/plugins/portals.js";
import FlagIcon from "vue-flag-icon";
import SiteTour from "@/plugins/siteTour.js";
import i18n from "./plugins/i18n.js";

import ServiceWorker from "./plugins/serviceWorker.js";

const app = createApp(App);

app.use(pinia);
app.use(i18n);
app.use(AuthPlugin);
app.use(CachePlugin);
app.use(HttpPlugin);
app.use(PowerBIPlugin);
app.use(FeatureFlagsPlugin);
app.use(ApplicationInsightsPlugin);
app.use(Sanitize, SanitizeDefaults);
app.use(currencySymbol);
app.use(googleCharts);
app.use(PortalVue);
app.use(FlagIcon);
app.use(SiteTour);
app.use(VueDOMPurifyHTML, {
  namedConfigurations: {
    plaintext: {
      USE_PROFILES: { html: false },
    },
  },
});

app
  .use(createRouter(app))
  .use(createStore(app))
  .use(ServiceWorker)
  .use(vuetify)
  .mount("#app");

// eslint-disable-next-line no-console
console.log(
  "==================",
  "Hi curious person!",
  `${import.meta.env.VITE_NAME}  Build: ${import.meta.env.VITE_VERSION}`,
  "=================="
);
